<script setup>
import { A11y, Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/vue'

defineProps({
  content: {
    type: Object,
    default: null,
  },
  type: {
    type: String,
    default: '',
  },
})

const prevButton = ref(null)
const nextButton = ref(null)
</script>

<template>
  <div class="content-block" :class="type">
    <!-- eslint-disable vue/no-v-html -->
    <div class="content-block__image">
      <swiper
        class="content-block__swiper"
        :navigation="{
          nextEl: nextButton,
          prevEl: prevButton,
        }"
        :modules="[A11y, Navigation]"
        :lazy="true"
      >
        <swiper-slide>
          <div class="content-block-swiper__slide__wrap">
            <div class="content-block__image">
              <SwiperPicture
                :image="{ custom: content.immagine.responsiveImage.src }"
                :lazy-load="true"
              />
            </div>
            <div
              v-if="content?.descrizione && type === 'default-type'"
              class="content-block__text"
            >
              <p class="heading-3" v-html="content.descrizione" />
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
    <div
      v-if="content?.descrizione && content?.nome && type === 'clienti-type'"
      class="content-block__text"
    >
      <!-- eslint-disable vue/no-v-html -->
      <div class="intro">
        <p class="text-3" v-html="content.descrizione" />
        <p class="text-4" v-html="content.nome" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SlideContent',
}
</script>

<style lang="scss">
@import './style.scss';
</style>
